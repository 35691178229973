.react-grid-item {
    border: black;
    border-width: thin;
    border-style: solid;
    text-align: center;
}

.react-grid-layout {
    border: black;
    border-style: solid;
}

.grid-container {
    width: 90%;
    margin-left: 10%;
}

.plan-resources {
    width: 10%;
    float: left;
    padding-top: 7px;
}

.plan-resource {
    height: 30px;
    margin-top: 10px;
    text-align: right;
    width: 100%;
}

.grid-item-success {
    border-color:#3aeb34;
    background-color: #3aeb3415;
}

.grid-item-warning {
    border-color: #e47200;
    background-color: #e4720015;
}

.remove-resource {
    float: left;
}

.resource-form {
    margin-bottom: 0px;
    flex-direction: column;
}

.plan-container {
    width: 90%;
    margin-left: 5%;
}

.plan-timeline {
    width: 90%;
    margin-left: 10%;
}

.date-range-picker {
    margin-left: 35%;
    margin-top: 10px;
}

.timeline {
    width: 100%;
    text-align: right;
    table-layout: fixed;
}

.timeline th {
    padding-left: 50px;
}

.overconsumed {
    color: #e47200;
}

.underconsumed {
    color: #3aeb34;
}