.project-container {
    width: 90%;
    margin-left: 5%
}

.projects-container {
    display: flex;
    align-items: stretch;
    margin-top: 50px;
    width: 90%;
    margin-left: 10%
}

.project-title {
    margin-top: 15px;
}

.project-card {
    width: 260px;
}

.project-estimate {
    width: 115px;
}

.merge-split {
    margin-left: 15px;
}