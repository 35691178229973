.App {
  text-align: center;
}

.locked {
  outline: auto;
}

.underlined {
  border-left-style: hidden;
  border-top-style: hidden;
  border-right-style: hidden;
  border-radius: unset;
}

.underlined:focus {
  outline: none !important;
  box-shadow: 0 0 0 #719ECE;
}
